import { DEALER_GROUPS, DEALER_GROUPS_ADDITIONAL_DATA } from '@/resources/dealer_groups/dealer_groups';
import { constructUrl, getBaseHeaders } from '@/services/service-config';
const baseURL = '/protected/958910/auto/container/';
const headers = {
    ...getBaseHeaders(),
    Accept: 'application/json;v=2'
};
const IMPACTERS_AUTO = '43626';
export const getDealerSettings = async (dealerId) => {
    const url = dealerId
        ? constructUrl(baseURL, `/~/dealers/settings?dealerId=${dealerId}`)
        : getDealerSettingsUrl(getShortNameFromPath());
    const res = await fetch(url, {
        method: 'GET',
        headers: {
            ...headers
        }
    });
    if (res.ok) {
        const dealerSettings = (await res.json());
        if (dealerSettings.settings.length !== 0)
            return prepareData(dealerSettings);
    }
    throw Error((await res.json()).developerText);
};
export const getShortNameFromPath = () => {
    return window.location.pathname.split('/')[1].toLowerCase() || null;
};
const prepareData = (dealerSettings) => {
    let main = null;
    let light = null;
    const settings = dealerSettings.settings;
    if (!Array.isArray(settings))
        return null;
    const flatSettings = {};
    settings.forEach((item) => {
        const checkValue = String(item.settingValue).toLowerCase();
        // also converts string booleans (ex: 'true') to boolean
        flatSettings[item.settingName] = ['true', 'false'].includes(checkValue) ? checkValue === 'true' : item.settingValue;
        if (item.settingName === 'accentHexColor') {
            main = item.settingValue != null ? `#${item.settingValue}` : null;
        }
        if (item.settingName === 'secondaryHexColor') {
            light = item.settingValue != null ? `#${item.settingValue}` : null;
        }
    });
    // Defaults to Black if theme is not found.
    flatSettings.theme = light != null && main != null ? { light, main } : { light: '#EEEEEE', main: '#000000' };
    // TODO remove this hardcoded dealer groups list
    flatSettings.isDealerGroup = false;
    if (DEALER_GROUPS[flatSettings.dealerIds] !== undefined && flatSettings.dealerIds !== IMPACTERS_AUTO) {
        flatSettings.childDealerIds = DEALER_GROUPS[flatSettings.dealerIds];
        flatSettings.childDealerInfo = DEALER_GROUPS_ADDITIONAL_DATA[flatSettings.dealerIds];
        flatSettings.isDealerGroup = true;
    }
    return flatSettings;
};
export const isShopFromHomeRedirect = () => {
    if (window.ENV_DATA.dealerRedirectShortnames.includes(getShortNameFromPath())) {
        return true;
    }
    return false;
};
const getDealerSettingsUrl = (shortname) => {
    if (!isShopFromHomeRedirect()) {
        return constructUrl(baseURL, `/~/dealers/settings?shortName=${shortname}`);
    }
    const referrer = document.referrer.split('/')[2];
    if (referrer) {
        return constructUrl(baseURL, `/~/dealers/settings?domainName=${referrer}`);
    }
    throw Error('Unable to retrieve dealer settings url.');
};
