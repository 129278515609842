export const displayText = {
    // Error Messages
    ERROR_MSG_404: "We can't find the page you're looking for.",
    ERROR_MSG_GENERIC: 'Hmm, something went wrong.',
    ERROR_MSG_VIN: "Oops! We're having trouble finding that vehicle.",
    ERROR_MSG_DROVE_OFF: 'Looks like that one drove off',
    ERROR_MSG_DEALER: "Looks like we couldn't find that page",
    ERROR_MSG_SMS: "Oops! We can't send a text message from a computer.",
    // Error Suggestions
    ERROR_SUGGESTION_1: 'Please reach out to the dealership.',
    ERROR_SUGGESTION_2: 'Please try again later or reach out to the dealership.',
    ERROR_SUGGESTION_DROVE_OFF: 'Sorry, that car is out of stock now. In the meantime, we picked out similar options for you to checkout. You can also contact us with questions or for more options.',
    ERROR_SUGGESTION_DEALER: 'Navigate back to the home page to continue shopping for vehicles',
    ERROR_SUGGESTION_OUT_OF_STOCK_NO_SIMILAR: "Sorry, that car is out of stock now. Let's find another option from our inventory. You can also contact us with questions or for more options.",
    ERROR_SUGGESTION_MOBILE: 'Please click the button from a Smartphone to start texting with us.',
    // CTA
    SEND_FEEDBACK: 'Send message',
    SEND_FEEDBACK_THANKS: 'Thanks',
    SEND_FEEDBACK_SUCCESS: `We'll be in touch with more info.`,
    SEND_FEEDBACK_FAILURE: 'Failed to send. Please try again later.',
    CTA_LIKE: "I'm interested",
    CTA_DISLIKE: 'Not for me',
    // Legal
    BMO_DISCLAIMER: "Pre-qualified pricing is based on a <b> dealer's preferred participating lender</b>. You may have other pre-qualified offers. Contact the dealer to discuss other financing options.",
    DEALER_ADVERTISED_PRICE: 'The price given by the dealer.  It may include offers and incentives that you may not qualify for. It may also exclude charges such as tax, title and license fees, and other fees. This price can change, and you should check with the dealer to see what is included or left out of the price shown.',
    FINANCE_DISCLOSURE_TEXT_TTL_DISABLED: 'The monthly payment and APR shown here are estimates of what might be available to you from a lender. Estimated monthly payment is based on dealer price and estimated APR. An estimate of tax, title, and license fees is not included in the Total Amount Financed, however you can opt to include TT&L with the toggle above to see how including TT&L may impact your financing terms. Estimated APR depends on your selected credit score range and term length and whether the vehicle you are financing is new or used. The estimated monthly payment and APR shown here is not an offer of credit. They can also change. You can find out your actual monthly payment and APR when you submit a credit application at the dealership. They may be different from the monthly payment and APR shown here.',
    FINANCE_DISCLOSURE_TEXT_TTL_ENABLED: 'The monthly payment and APR shown here are estimates of what might be available to you from a lender. Estimated monthly payment is based on dealer price, estimated APR, and an estimate for tax, title, and license fees. Estimated APR depends on your selected credit score range and term length and whether the vehicle you are financing is new or used. The estimated monthly payment and APR shown here is not an offer of credit. They can also change. You can find out your actual monthly payment and APR when you submit a credit application at the dealership. They may be different from the monthly payment and APR shown here.',
    LEASE_DISCLOSURE_TEXT: 'The monthly payment and money factor shown here are estimates of what might be available to you from a lender. Estimates are based on dealer price, your selected credit score range, max yearly mileage, and lease term. A security deposit will be required upon leasing, and is not accounted for in the estimated terms displayed. At the end of your lease a [DISPOSITION FEE] disposition fee may be due and miles above your selected yearly total miles will incur a [OVERAGE FEE]/mile overage charge. The estimated monthly payment and money factor shown here are not an offer of lease. They can also change. You can find out your actual monthly payment and money factor when you submit a credit application at the dealership. They may be different from the monthly payment and money factor shown here.',
    CASH_DISCLOSURE_TEXT_TTL_DISABLED: 'Estimated net amount due is based on your selections above, and is subject to change. An estimate of tax, title, and license fees is not included in the Total Estimated Net Amount Due, however you can opt to include TT&L in terms with the toggle above. This is not a contract, nor an offer to purchase. You can change your selections at any time prior to finalizing your deal with the dealer.',
    CASH_DISCLOSURE_TEXT_TTL_ENABLED: 'Estimated net amount due is based on your selections above, and is subject to change. An estimate of tax, title, and license fees is included in the Total Estimated Net Amount Due, however you can opt not to include TT&L with the toggle above. This is not a contract, nor an offer to purchase. You can change your selections at any time prior to finalizing your deal with the dealer.',
    // Unlock Price
    UNLOCK_PRICE_HEADER_TITLE: 'Unlock Payments',
    UNLOCK_PRICE_SUB_TITLE: `Let's build your estimate`,
    UNLOCK_PRICE_SUB_TEXT: 'Use our calculator to build a payment and rate that works for you, then share the estimate with us.',
    // Share Anonymous Lead
    SHARE_ANON_LEAD_HEADER_TITLE: 'Get Started',
    SHARE_ANON_LEAD_SUB_TITLE: `Let's work out the details`,
    SHARE_ANON_LEAD_SUB_TEXT: 'Let us know a little about you, then tell us your preferences and prepare for a visit.',
    // Easterns
    EASTERNS_PROCESSING_DISCLOSURES: 'Price excludes dealer processing charge: $799'
};
