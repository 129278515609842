import { constructUrl, getBaseHeaders } from '@/services/service-config';
const FEATURE_TOGGLE_URL = '/protected/958910/auto/container/{dealerId}/feature/{featureName}';
export const getFeatureToggle = ({ dealerIds }, featureName) => {
    const url = constructUrl(FEATURE_TOGGLE_URL.replace('{dealerId}', dealerIds).replace('{featureName}', featureName));
    const options = {
        headers: {
            ...getBaseHeaders(),
            Accept: 'application/json;v=2'
        }
    };
    const onSuccess = (r) => {
        if (!r.ok)
            throw Error('Feature Toggle request failed.');
        return r.json();
    };
    return fetch(url, options).then(onSuccess);
};
