import { STORED_SESSION_ID_KEY, StratumService, addGlobalPlugin } from '@capitalone/stratum-observability';
import { GoogleAnalytics4ASCPlugin } from '@cof/stratum/plugin/google-analytics-4-asc';
import { NewRelicPluginFactory } from '@cof/stratum/plugin/new-relic';
import { CORRELATION_ID_KEY, OneTagPluginFactory } from '@cof/stratum/plugin/one-tag';
import { TAG_CATALOG } from '@cof/tag-catalog-white-label-ui';
import pjson from '../../package.json';
import { Product } from '@/models/product';
import StratumTaggingQueue from '@/utils/StratumTaggingQueue';
import { setLocalStorageValue, setSessionStorageValue } from '@/utils/storage-utilities';
const w = window;
setLocalStorageValue(CORRELATION_ID_KEY, '0');
setSessionStorageValue(STORED_SESSION_ID_KEY, '0');
// only exported for testing in TaggingService.test.tsx ... do not use elsewhere
export let stratum = {};
let oneTagPlugin = {};
let googleAnalytics4ASCPlugin = {};
let isStratumInitialized = false;
const publishTagQueue = new StratumTaggingQueue();
function setDealerId(dealerId) {
    w.c1_analytics = {
        domain_identifier: btoa(dealerId)
    };
    setExternalVar('dealerId', dealerId);
}
function parseDataForDealerId(data) {
    if (oneTagPlugin.getContext && oneTagPlugin.getContext('dealerId'))
        return;
    if (data?.dealerId) {
        // eslint-disable-line
        setDealerId(data.dealerId);
    }
    else if (data?.deeplink?.dealerId) {
        // eslint-disable-line
        setDealerId(data.deeplink.dealerId);
    }
    else if (data?.vehicle?.dealerId) {
        // eslint-disable-line
        setDealerId(data.vehicle.dealerId);
    }
    else if (data?.dealerIds) {
        setDealerId(data.dealerIds);
    }
}
export async function instantiateStratum(callback, googleTrackingId, isShowroom) {
    if (isStratumInitialized)
        return;
    oneTagPlugin = OneTagPluginFactory();
    if (googleTrackingId && isShowroom) {
        googleAnalytics4ASCPlugin = new GoogleAnalytics4ASCPlugin({ trackingId: googleTrackingId });
        addGlobalPlugin(googleAnalytics4ASCPlugin);
    }
    stratum = await getShowroomTC();
    callback();
    isStratumInitialized = true;
    if (isReadyToPublishTag())
        publishTagQueue.nextJob();
}
const getShowroomTC = () => {
    return import('@cof/tag-catalog-white-label-ui').then(catalog => {
        return new StratumService({
            catalog: { items: TAG_CATALOG, catalogVersion: catalog.CATALOG_VERSION },
            productName: catalog.PRODUCT_NAME,
            productVersion: pjson.version,
            plugins: [oneTagPlugin, NewRelicPluginFactory()]
        });
    });
};
const isReadyToPublishTag = () => {
    return (isStratumInitialized &&
        oneTagPlugin.getContext &&
        oneTagPlugin.getContext('dealerId') !== undefined &&
        oneTagPlugin.getContext('applicationId') !== undefined);
};
export const initTealium = (data) => {
    parseDataForDealerId(data);
    if (window.document.getElementById('init-tealium'))
        return;
    const script = window.document.createElement('script');
    script.src = window.ENV_DATA.oneTagUrl;
    script.async = true;
    script.type = 'text/javascript';
    script.id = 'init-tealium';
    window.document.head.appendChild(script);
    if (isReadyToPublishTag())
        publishTagQueue.nextJob();
};
export const setExternalVar = (key, value) => {
    if (Object.keys(oneTagPlugin).length === 0)
        return false;
    return oneTagPlugin.setContext(key, value);
};
export const setGA4ExternalVar = (key, value) => {
    if (Object.keys(googleAnalytics4ASCPlugin).length === 0)
        return false;
    return googleAnalytics4ASCPlugin.setContext(key, value);
};
export const publishTag = (key, options, callback) => {
    if (!isReadyToPublishTag()) {
        publishTagQueue.enqueue(() => {
            const ret = stratum.publish(key, options);
            if (callback !== undefined)
                callback();
            return ret;
        });
        return undefined;
    }
    const ret = stratum.publish(key, options);
    if (callback !== undefined)
        callback();
    return ret;
};
export const getAppIdByProduct = (productInput) => {
    let result = '';
    switch (productInput) {
        case Product.Showroom:
            result = 'coaf-privatelabel';
            break;
        case Product.Maestro:
            result = 'maestro';
            break;
        case Product.Muse:
            result = 'muse';
            break;
        default:
            result = 'coaf-privatelabel';
            break;
    }
    return result;
};
export function setOneTagConfigAppIdByProduct(productInput) {
    const appId = getAppIdByProduct(productInput);
    // Setup OneTag config
    window.oneTagConfig = {
        oneTagProfile: 'others',
        oneTagEnv: window.ENV_DATA.oneTagEnv,
        app_id: appId
    };
}
